import { createCustomIcon } from '~/adapters/antd';
import _AddIcon from '~/assets/images/icon-add.svg';
import _BookIcon from '~/assets/images/icon-book.svg';
import _CalendarIcon from '~/assets/images/icon-calendar.svg';
import _CheckIcon from '~/assets/images/icon-check.svg';
import _ClockIcon from '~/assets/images/icon-clock.svg';
import _DisputeIcon from '~/assets/images/icon-dispute.svg';
import _EmailIcon from '~/assets/images/icon-email.svg';
import _EthIcon from '~/assets/images/icon-eth.svg';
import _HelpIcon from '~/assets/images/icon-help.svg';
import _HourGlassIcon from '~/assets/images/icon-hour-glass.svg';
import _InfoIcon from '~/assets/images/icon-info.svg';
import _NotificationIcon from '~/assets/images/icon-notification.svg';
import _RemoveIcon from '~/assets/images/icon-remove.svg';
import _SettingsIcon from '~/assets/images/icon-settings.svg';
import _TelegramIcon from '~/assets/images/icon-telegram.svg';
import _WarningIcon from '~/assets/images/icon-warning.svg';

export const AddIcon = createCustomIcon(_AddIcon);
export const BookIcon = createCustomIcon(_BookIcon);
export const CalendarIcon = createCustomIcon(_CalendarIcon);
export const CheckIcon = createCustomIcon(_CheckIcon);
export const ClockIcon = createCustomIcon(_ClockIcon);
export const DisputeIcon = createCustomIcon(_DisputeIcon);
export const EmailIcon = createCustomIcon(_EmailIcon);
export const EthIcon = createCustomIcon(_EthIcon);
export const HelpIcon = createCustomIcon(_HelpIcon);
export const HourGlassIcon = createCustomIcon(_HourGlassIcon);
export const InfoIcon = createCustomIcon(_InfoIcon);
export const NotificationIcon = createCustomIcon(_NotificationIcon);
export const RemoveIcon = createCustomIcon(_RemoveIcon);
export const SettingsIcon = createCustomIcon(_SettingsIcon);
export const TelegramIcon = createCustomIcon(_TelegramIcon);
export const WarningIcon = createCustomIcon(_WarningIcon);
